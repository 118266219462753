<template>
  <section class="content">
    <div class="card card-info">
      <!-- <div class="card-header">
        <h3 class="card-title">Daftar Topup Saldo</h3>
      </div> -->
      <div class="card-body">
        <div class="row form-horizontal justify-content-end">
            <div class="col-12 col-sm-auto mb-3">
              <label class="control-label my-2 ml-2 font-weight-normal">Tanggal</label>
            </div>
            <div class="col-12 col-sm-auto mb-3 mr-auto">
              <div class="input-group">
                <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                <div class="input-group-append">
                <div class="input-group-text" style="background-color: #fff" >
                  <span><span class="fa fa-calendar"></span></span>
                </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-auto mb-3">
              <label class="control-label my-2 ml-2 font-weight-normal">Filter Pencarian</label>
            </div>
            <div class="col-12 col-sm-auto mb-3">
              <select class="form-control" v-model="filter.rekening_id" @change="onReload">
                  <option value="" disabled selected>Pilih Bank</option>
                  <optgroup v-for="(bankData, key) in totalBank" :label="key.toUpperCase()">
                    <option v-for="bank in bankData" v-bind:value="bank.rekening_id">
                        {{ key == 'manual' ? bank.label : bank.name }}
                    </option>
                  </optgroup>
                  <option value="0">Semua</option>
              </select>
            </div>
            <div class="col-12 col-sm-auto mb-3">
                <div class="btn-group" ref="toolbar">
                    <button type="button" data-action="read" class="btn btn-default">
                      <i class="fa fa-sync-alt mr-1"></i> Refresh
                    </button>
                    <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="processing" v-on:click="downloadXlsx">
                      <i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" ref="tblsaldoapprove" id="tblsaldoapprove">
            <thead>
              <tr>
                <th>ID SHIPPER</th>
                <th>SHIPPER</th>
                <th>NILAI TOPUP</th>
                <th>KODE UNIK</th>
                <th>NILAI TRANSFER</th>
                <th>TANGGAL</th>
                <th>JENIS BANK</th>
                <th>INVOICE ID</th>
                <th>STATUS</th>
                <th>NOTE</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";

export default {
  nama: "Daftar Topup Saldo Approval",
  data() {
    return {
      errors: [],
      totalBank: [],
      method: "",
      form: {
        action: "",
      },
      filter: {
				dt1: moment().subtract(1, 'months'),
				dt2: moment(),
        rekening_id: '',
      },
      //   saldo: {},
      //   total: "",
      roles: "",
      role_id: "",
      processing: false
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch("/payment/saldo/bank?tipe=topup")
      .then((res) => {
          if (res.status === 201) {} else if (res.status === 400) {}
          return res.json();
      })
      .then((js) => {
          this.totalBank = js.data;
      });

    if(this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}

    this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
  },
  methods: {
    onReload() {
			this.tbl.api().ajax.reload();
		},
		downloadXlsx: function (e) {
			const self = this;
			self.processing = true;

			var table = $('#tblsaldoapprove').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 14000) {
				Swal.fire("Proses gagal", `Maksimal data Withdraw sebanyak kurang lebih 14.000 data.`, "error");
				self.processing = false;
				return false;
			}

			var filter = JSON.parse(JSON.stringify(self.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");

      authFetch("/report/topup/excell", {
				method: "POST",
				body: data,
			}).then((response) => response.blob())
			.then((blob) => {
        setTimeout(() => {
          self.processing = false;
        }, 1000);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "topup.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
		},
    handleClick(e) {
      if (e.target.matches("button")) {
        this.form.action = e.target.dataset.action;
        var data = Object.keys(this.form)
          .map(
            (key) =>
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent(this.form[key])
          )
          .join("&");
        if (this.form.action == "confirm") {
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/payment/admin/approval/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        } else if (this.form.action == "tolak") {
          Swal.fire({
            title: "Tolak?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/payment/admin/approval/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        // if (e.target.dataset.action == "confirm") {
        // }
        // console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;

    new Daterangepicker(
			e.daterange,
			{
				startDate: !this.dt1 ? moment().subtract(1, 'months') : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				// self.data_type = self.data_type;
				// self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onReload();
			}
		);

    this.tbl = createTable(e.tblsaldoapprove, {
        title: "",
        roles: self.$route.params.roles,
        selectedRowClass: "",
        serverSide: true,
        frame: false,
        displayLength: 10,
        lengthChange: true,
        processing: true,
        toolbarEl: e.toolbar,
        order: [[ 5, "desc" ]],
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        ajax: "/payment/admin/topup",
        columns: [
        { data: "shipper_code" },
        { data: "shipper_name" },
        { data: "amount", render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
        }, },
        { data: "unique_code", render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
        }, },
        { data: "debit", render: function (data, type, row, meta) {
          return "Rp " + formatCurrency(data);
            // let nilai = 0;
            // nilai = row.debit+row.unique_code;
        }, },
        { data: "created_on",
        render: function (data, type, row, meta) {
            return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "bank", width: "90px",
        render: function (data, type, row, meta) {
            if (data !== '') {
                return `<span>${row.bank} (${row.code}) <br> ` + (row.payment_method != 'winpay' ? row.rekening + ` <br> ` : '') + (row.payment_method != 'winpay' ? row.atas_nama : "Payment Gateway") + `</span>`;
            } else {
                return "";
            }
        } },
        { data: "invoice_code" },
        { data: "status", render: function (data, type, row, meta) {
            let status = '';
            if(row.active < 1)
            {
              return `<span class="badge badge-danger">DITOLAK</span>`;
            } else {
              if (row.status == 0) {
                  status = `<span class="badge badge-warning">PENDING</span>`;
              } else {
                  status = `<span class="badge badge-success">SUKSES</span>`;
              }
            }
            return status;
        } },
        { data: "note",
        sortable: false,
        render: function (data, type, row, meta) {
            if (row.status == 1) {
                if(row.approved_by == 0)
                {
                    return 'Approved By System';
                } else {
                    return `Approved By ${row.display_name}`;
                }
            } else {
                return '';
            }
        } },
        { data: "action", sortable: false, render: function (data, type, row, meta) {
            let act = "";

            if (row.status > 0 || row.active < 1) {
                act = "";
            } else {
                if (row.payment_method == 'winpay') {
                    act = "Can't approve by manual";
                } else {
                    act = `<div class="btn-group">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Klik
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                    <button type="button" class="btn btn-sm btn-success dropdown-item" data-action="confirm" data-active="1" data-id="` +
                            row.invoice_code +
                            `"> Konfirmasi </button>
                    <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="tolak" data-active="0" data-id="` +
                            row.invoice_code +
                            `"> Tolak </button>
                    </div>
                </div>`;
                }
            }

            return act;
        } },
        ],
        filterBy: [0, 1, 2, 4, 7],
        paramData: function (d) {
          var filter = JSON.parse(JSON.stringify(self.filter));
          d.dt1 = self.dateStart;
          d.dt2 = self.dateEnd;
          for (const key in filter) {
              d[key] = filter[key] === false ? 0 : filter[key];
          }
        },
        rowCallback: function (row, data) {
        },
				initComplete: function () {
					$('.loading-overlay').removeClass('show');
				},
    });
    },
};
</script>
<style scoped>
</style>